import Crud from '../components/DataManagers/Crud'
import Add from '../components/DataManagers/Add'
import Edit from '../components/DataManagers/Edit'
import View from '../components/DataManagers/View'

let routes = {
    path: '/wikiClients',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/datamanagers/add/',
            name: 'Adicionar Regitros',
            component: Add,
            meta:{ requiresAuth:false }
           
        },
        {
            path: '/datamanagers/edit/:id',
            name: 'Editar Regitros',
            component: Edit,
           
        },      
        {
            path: '/data_managers/',
            name: 'Gerenciar Dados',
            component: Crud,
            
        },
        {
            path: '/datamanagers/view/:id',
            name: 'Vizualizar Regitros',
            component: View,
           
        },      
    ]
};


export default routes