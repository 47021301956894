<template>
    <div>
        <base-crud crudName="Gerenciar Dados" newText="Gerenciar Dados" :table="dataManagersTable" :columns="columns"
            :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true"
            :enableView="true" :dataForm="dataForm" :customFilters="customFilters" :extra="extra">
        </base-crud>
    </div>
</template>

<script>
import BaseCrud from '../Base/BaseCrud'

export default {
    data() {
        return {
            customFilters: [],
            extra: [],
            dataManagersTable: 'data_managers',
            url: '',
            columns: ['id', 'user_name', 'email', 'platform_name', 'request_type', 'phone', 'message', 'actions'],
            tableData: ['id', 'user_name', 'email', 'platform_name', 'request_type', 'phone', 'message'],
            debounce: 800,
            options: {
                filterByColumn: true,
                filterable: ['id', 'user_name', 'email', 'platform_name', 'request_type', 'phone', 'message'],
                listColumns: {},
                sortable: ['id', 'user_name', 'email', 'platform_name', 'request_type', 'phone', 'message'],
                pagination: { chunk: 10, dropdown: false, nav: 'scroll' },
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    'id': 'ID',
                    'user_name': 'Nome do Usuário',
                    'email': 'E-mail',
                    'platform_name': 'Nome da Plataforma',
                    'request_type': 'Tipo de Requisição',
                    'phone': 'Telefone',
                    'message': 'Mensagem',
                    'actions': 'Ações'
                },
                templates: {

                    request_type: (h, row, index) => {

                        return row.request_type == 3 ? 'Exclusao de dados' : 'Exclusao de contas';
                    },
                },
                requestFunction(data) {
                    let requestData = {};
                    let query = this.$parent.$parent.query(data.query);

                    requestData.params = data;
                    requestData.params.query = "";

                    return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                            this.dispatch("error", e);
                        }.bind(this)
                    );
                },
            },
            endPoint: 'datamanagers/',
            dataForm: {}
        }
    },
    methods: {
        query(query) {
            let columns = {
                id: 'id',
                user_name: 'user_name',
                email: 'email',
                platform_name: 'platform_name',
                request_type: 'request_type',
                phone: 'phone',
                message: 'message'
            };
            let filters = '&orderByDesc=id&';
            $.each(query, function (index, value) {
                filters += columns[index] + '=' + value + '&';
            });
            return filters;
        }
    },
    components: {
        BaseCrud
    }
}
</script>

<style scoped>
.VuePagination {
    text-align: center;
}
</style>
