<template>
  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="card-body text-center">
          <div class="card-header">
            <h4 style="color: blue;">Solicitação de Exclusão de Dados ou Conta</h4>
          </div>
          <div class="card-body p-3" style="text-align: justify;">
            <h6>De acordo com a legislação de proteção de dados, você tem o direito de solicitar a exclusão dos seus
              dados
              pessoais ou a remoção completa da sua conta em nossos aplicativos.
            </h6>
            <h6>
              Antes de prosseguir, tenha em mente que:
              A exclusão de dados removerá informações específicas associadas ao seu perfil, mas sua conta permanecerá
              ativa.<br>
            </h6>
            <h6>
              A exclusão da conta é irreversível e apagará todos os seus dados, incluindo histórico, configurações e
              acessos.<br>
            </h6>
            <h6>
              Após enviar sua solicitação, nossa equipe analisará o pedido e poderá entrar em contato para confirmar sua
              identidade e garantir a segurança do processo. O prazo para conclusão pode variar conforme as
              regulamentações
              aplicáveis.
            </h6>

          </div>
          <div class="card-footer" style="color: blue;"> Caso tenha dúvidas, entre em contato pelo nosso suporte.</div>
        </div>

        <div class="col-md-4">
          <label for="user_name">Nome do Usuário</label>
          <input type="text" class="form-control" v-model="dataManagers.user_name" />
          <br />
        </div>

        <div class="col-md-4">
          <label for="email">E-mail</label>
          <input type="email" class="form-control" @input="validateEmail" v-model="dataManagers.email" />
          <span v-if="emailError" style="color: red;">E-mail inválido</span>
          <br />
        </div>

        <div class="col-md-4">
          <label for="phone">Telefone</label>
          <input type="text" v-mask="phoneMask" class="form-control" v-model="dataManagers.phone" />
          <br />
        </div>

        <div class="col-md-4">
          <label for="platform_name">Nome da Plataforma</label>
          <input type="text" class="form-control" v-model="dataManagers.platform_name" />
          <br />
        </div>

        <div class="col-md-4">
          <label for="request_type">Tipo de Requisição</label>
          <select class="form-control" v-model="dataManagers.request_type">
            <option value="3">Exclusao de dados</option>
            <option value="5">Exclusao de contas</option>

          </select>
        </div>
      </div>

      <div class="row">


        <div class="col-md-12">
          <label for="message">Mensagem</label>
          <textarea class="form-control custom-textarea" v-model="dataManagers.message"></textarea>
          <br />
        </div>
      </div>
    </div>

    <div class="card-body">
      <hr />
      <div :class="{ 'd-flex justify-content-between': isUserLoggedIn, 'd-flex justify-content-end': !isUserLoggedIn }">
        <button @click="$router.go(-1)" class="btn btn-info" v-if="isUserLoggedIn">
          <i class="fa fa-arrow-left"></i> Voltar
        </button>
        <button class="btn btn-success" @click="save">
          Enviar 
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      dataManagers: {
        user_name: "",
        email: "",
        platform_name: "",
        request_type: "",
        phone: "",
        message: ""
      },
      emailError: false,
      errors: null
    };
  },

  mounted() {
    this.updateHeaderVisibility();
    let id = this.$route.params.id;
    if (id) {
      this.getDataManager(id);
    }
  },
  methods: {
    save() {
      let api = this.$store.state.api + "datamanagers";
      let form = new FormData();

      form.append("user_name", this.dataManagers.user_name);
      form.append("email", this.dataManagers.email);
      form.append("platform_name", this.dataManagers.platform_name);
      form.append("request_type", this.dataManagers.request_type);
      form.append("phone", this.dataManagers.phone);
      form.append("message", this.dataManagers.message);

      if (this.dataManagers.id) {
        api += "/" + this.dataManagers.id;
        form.append("_method", "PUT");
      }

      this.$http.post(api, form)
        .then(() => {
          this.$message("Sucesso", "Registro salvo com sucesso", "success");
          this.$router.push("/data_managers");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (typeof this.errors === "object") {
            let html = "<ul>";
            Object.entries(this.errors).forEach(([key, value]) => {
              html += `<li>${value[0]}</li>`;
            });
            html += "</ul>";
            this.errors = html;
          }
          this.$message(null, this.errors, "error");
        });
    },

    getDataManager(id) {
      let api = this.$store.state.api + "datamanagers/" + id;
      this.$http.get(api)
        .then(response => {
          this.dataManagers = response.data.data;
        })
        .catch(error => {
          this.errors = error.response.data;
          this.$message(null, this.errors, "error");
        });
    },

    validateEmail() {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.emailError = !emailRegex.test(this.dataManagers.email);
    },

    updateHeaderVisibility() {
      const header = document.getElementById('header');
      if (header) {
        header.style.display = this.isUserLoggedIn ? '' : 'none';
      }
    }
  },
  computed: {
    phoneMask() {
      return this.dataManagers.phone.length > 13 ? '(##) #####-####' : '(##) ####-####';
    },
    isUserLoggedIn() {

      let isLogged = this.$store.state.token ? true : false;
      console.log('aquiiiii');
      console.log(this.$store.state.token);
      console.log(isLogged);
      return isLogged;
    }
  }
};
</script>

<style>
.required {
  color: red;
}

.custom-textarea {
  height: 200px !important;
}
</style>


<style>
.required {
  color: red;
}

.custom-textarea {
  height: 200px !important;
}
</style>
