<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Visualizar Registro</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nome do Usuário:</label>
                  <p class="form-control-static">{{ dataManagers.user_name }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>E-mail:</label>
                  <p class="form-control-static">{{ dataManagers.email }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nome da Plataforma:</label>
                  <p class="form-control-static">{{ dataManagers.platform_name }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tipo de Requisição:</label>
                  <p class="form-control-static">{{ dataManagers.request_type }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Telefone:</label>
                  <p class="form-control-static">{{ dataManagers.phone }}</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Mensagem:</label>
                  <p class="form-control-static">{{ dataManagers.message }}</p>
                </div>
              </div>
            </div>

            <hr />
            <br />
            <div class="row">
              <div class="col-md-12 text-right">
                <button  @click="$router.go(-1)" class="btn btn-info">
                  <i class="fa fa-arrow-left"></i> Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataManagers: {
        user_name: "",
        email: "",
        platform_name: "",
        request_type: "",
        phone: "",
        message: "",
      },
      errors: null,
    };
  },

  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.getDataManager(id);
    }
  },

  methods: {
    getDataManager(id) {
      this.$http.get(this.$store.state.api + `datamanagers/${id}`).then((response) => {
        this.dataManagers = response.data.data[0];
      });
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>
